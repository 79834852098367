
import { Component, OnInit } from '@angular/core';
import { BeforeSlideDetail } from 'lightgallery/lg-events';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Publication } from 'src/app/models/publication';
import { environment } from 'src/environments/environment';
import { AppSettingService } from '../../../services/appsetting.service';
import { ContentService } from '../../../services/content.service';
import { SermonService } from '../../../services/sermon.service';
import { SmallItemService } from '../../../services/smallItem.service';

@Component({
  selector: 'app-home-page-eight',
  templateUrl: './home-page-eight.component.html',
  styleUrls: ['./home-page-eight.component.scss']
})
export class HomePageEightComponent implements OnInit {
  photoUrl: string = environment.serverUrl
  basePhotoUrl: string = environment.serverUrl

  isVisible: boolean = true;
  breakpoint: number = 991;
  smallAnnouncement: any;
  isSmallAnnouncement: boolean = false;
  tabs: any[] = [];
  publication: Publication[] = [];
  specialSermon: any;
  eventStatu: string;
  educationStatu: string;
  appSettings: any;

  constructor(private contentService: ContentService, private appSettingService: AppSettingService, private smallItemService: SmallItemService, private sermonService: SermonService) { }


  dropdownOpen: boolean = false;
  public innerWidth: any;
  ngOnInit(): void {
    this.getSmallAnnouncement();
    this.getAllTabContent();
    this.getSpecialSermon();
    this.getAppSettings()
  }

  getAppSettings() {
    this.appSettingService.getAppSetting().subscribe((data: any) => {
      this.appSettings = data;
      this.educationStatu = data.find(item => item.key === "egitimdurum")?.value;
      this.eventStatu = data.find(item => item.key === "etkinlikdurum")?.value;
    })
  }


  getSmallAnnouncement() {
    this.smallItemService.getAllSmallAnnouncement().subscribe((data: any) => {
      if (data.data.some(announcement => announcement.isActive = true)) {
        this.isSmallAnnouncement = true;
      }
      this.smallAnnouncement = data.data.filter(news => news.isActive === true).sort((a, b) => a.order - b.order);

    })
  }


  getAllTabContent() {
    const lang = localStorage.getItem('language');
    this.contentService.getAllTabContent().subscribe((data: any) => {
      this.tabs = data.data.filter(tab => tab.isActive === true && tab.isDeleted === false && tab.languageCode === lang)
      this.tabs.forEach(tab => {
        tab.contents = tab.contents.filter(content => content.isActive && !content.isDeleted);
      });
    })
  }


  getSpecialSermon() {
    this.sermonService.getSpecialContent().subscribe((data: any) => {
      this.specialSermon = data.data;

    })
  }


  isIconOpen = false;

  toggleIcons() {
    this.isIconOpen = !this.isIconOpen;
  }

  currentTab = 'tab1';
  switchTab(event: MouseEvent, tab: string) {
    event.preventDefault();
    this.currentTab = tab;
  }

  //TAB DUYURULAR

  onBeforeSlide = (detail: BeforeSlideDetail): void => {
    const { index, prevIndex } = detail;
  };

  classApplied2 = false;
  toggleClass2() {
    this.classApplied2 = !this.classApplied2;
  }


  carouselSmallAnnouncementSlide: OwlOptions = {
    loop: false,
    nav: false,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    items: 1,
    navText: [
      "<i class='bx bx-left-arrow-alt'></i>",
      "<i class='bx bx-right-arrow-alt'></i>"
    ],


  }

  selectedItem = null;
  toggleAccordionItem(list) {
    list.open = !list.open;
    if (this.selectedItem && this.selectedItem !== list) {
      this.selectedItem.open = false;
    }
    this.selectedItem = list;
  }


  detectHeader() {
    this.innerWidth = window.innerWidth;
    this.isVisible = this.innerWidth >= this.breakpoint;
  }

  dropdownMenu() {
    this.dropdownOpen = !this.dropdownOpen;

  }

}


