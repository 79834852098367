
import { Component, OnInit } from '@angular/core';
import { AppSettingService } from 'src/app/services/appsetting.service';
import { FooterService } from 'src/app/services/footer.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  address: any;
  telephone: any;
  telephone2: any;
  fax: any;
  facebook: any;
  twitter: any;
  instagram: any;
  youtube: any;
  hour: any;
  email1: any;
  email: any;
  public href = window.location.href;
  footerArea: any;


  constructor(private footerService: FooterService, private appSettingService: AppSettingService) { }

  ngOnInit(): void {
    this.getAppSettings();
    this.getFooterMenus();
  }


  getFooterMenus() {
    const url = this.href.match(/\.fr(\/[^/]*)?$/);

    this.footerService.getFooter().subscribe((data: any) => {
      this.footerArea = data.data.map(element => {
        let footerItems = element.footerItems.sort((a, b) => a.order - b.order);

        if (url != null) {
          footerItems = footerItems.filter(value => value.linkUrl !== url.input);
        }
        return {
          ...element,
          footerItems: footerItems
        };
        
      });
    });
  }


  getAppSettings() {
    this.appSettingService.getAppSetting().subscribe((data: any) => {
      this.address = data.find(item => item.key === "adres")?.value;
      this.telephone = data.find(item => item.key === "telefon")?.value;
      this.facebook = data.find(item => item.key === "facebook")?.value;
      this.twitter = data.find(item => item.key === "twitter")?.value;
      this.instagram = data.find(item => item.key === "instagram")?.value;
      this.youtube = data.find(item => item.key === "youtube")?.value;
      this.hour = data.find(item => item.key === "calismasaati")?.value;
      this.email1 = data.find(item => item.key === "email1")?.value;
      this.email = data.find(item => item.key === "email")?.value;


    })
  }


}
