import { Component, OnInit } from '@angular/core';

import { OwlOptions } from 'ngx-owl-carousel-o';
import { environment } from 'src/environments/environment';
import { ContentService } from '../../../services/content.service';

@Component({
  selector: 'app-home-page-eleven',
  templateUrl: './home-page-eleven.component.html',
  styleUrls: ['./home-page-eleven.component.scss']
})
export class HomePageElevenComponent implements OnInit {
  slider: any[] = [];
  photoUrl: string = environment.serverUrl
  basePhotoUrl: string = environment.baseServerUrl
  constructor(private contentService: ContentService) { }

  ngOnInit(): void {
    this.contentService.getAllSlider().subscribe((data: any) => {
      this.slider = data.sort((a, b) => a.order - b.order);
      
    });
  }

  gymHomeSlides: OwlOptions = {
    autoplayHoverPause: true,
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    autoHeight: true,
    autoplay: true,
    dots: false,
    loop: true,
    nav: true,
    items: 1,
    navText: [
      "<i class='flaticon-arrows'></i>",
      "<i class='flaticon-right-arrow'></i>"
    ]
  }

}
