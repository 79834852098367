<app-banner [data]="{content: 'EVENTS' | translate, title: event.title , link:'/etkinlikler'}"></app-banner>

<div class="blog-details-area pt-70 pb-100">
    <div class="container">
        <div class="row">
            <div>
                <div class="blog-details-desc ">
                    <div class="mission-area ptb-70" *ngIf="event?.eventImages?.length>0">
                        <div class="container">
                            <div class="mission-content">
                                <div class="mission-slides">
                                    <owl-carousel-o [options]="missionSlides">
                                        <ng-template carouselSlide *ngFor="let item of event?.eventImages">
                                            <img [src]="event.isShare=== true ? basePhotoUrl+item?.imagePath : photoUrl+item?.imagePath ">
                                        </ng-template>
                                    </owl-carousel-o>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="container ">
                        <div class="row">
                            <div class="col-md-8">
                                <div class="article-content">
                                    <div [innerHTML]="event.description"></div>

                                </div>
                            </div>
                            <div class="col-md-4 events-detail">
                                <div class="events-detail-list">
                                    <i class="fa-solid fa-location-dot"></i>
                                    <p>{{event.address}}</p>
                                </div>
                                <div class="events-detail-list" *ngIf="event?.startDateTime">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 35 35"
                                        fill="none">
                                        <g clip-path="url(#clip0_1_485)">
                                            <path
                                                d="M23.7356 20.5906L18.8557 16.9306V9.47519C18.8557 8.72557 18.2497 8.11963 17.5001 8.11963C16.7505 8.11963 16.1445 8.72557 16.1445 9.47519V17.6085C16.1445 18.0354 16.3452 18.4381 16.6868 18.6929L22.1089 22.7595C22.3529 22.9425 22.6376 23.0307 22.9209 23.0307C23.3343 23.0307 23.741 22.8449 24.0067 22.4871C24.4568 21.8892 24.3348 21.0393 23.7356 20.5906Z"
                                                fill="#333333" />
                                            <path
                                                d="M17.5 0C7.84991 0 0 7.84991 0 17.5C0 27.1501 7.84991 35 17.5 35C27.1501 35 35 27.1501 35 17.5C35 7.84991 27.1501 0 17.5 0ZM17.5 32.2889C9.34644 32.2889 2.71106 25.6536 2.71106 17.5C2.71106 9.34644 9.34644 2.71106 17.5 2.71106C25.6549 2.71106 32.2889 9.34644 32.2889 17.5C32.2889 25.6536 25.6536 32.2889 17.5 32.2889Z"
                                                fill="#333333" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1_485">
                                                <rect width="35" height="35" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>

                                    <p>{{'START_DATE'|translate}}: {{event.startDateTime| date:'dd.MM.yyyy hh:mm'}}

                                </div>
                                <div class="events-detail-list" *ngIf="event?.endDateTime">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 35 35"
                                        fill="none">
                                        <g clip-path="url(#clip0_1_485)">
                                            <path
                                                d="M23.7356 20.5906L18.8557 16.9306V9.47519C18.8557 8.72557 18.2497 8.11963 17.5001 8.11963C16.7505 8.11963 16.1445 8.72557 16.1445 9.47519V17.6085C16.1445 18.0354 16.3452 18.4381 16.6868 18.6929L22.1089 22.7595C22.3529 22.9425 22.6376 23.0307 22.9209 23.0307C23.3343 23.0307 23.741 22.8449 24.0067 22.4871C24.4568 21.8892 24.3348 21.0393 23.7356 20.5906Z"
                                                fill="#333333" />
                                            <path
                                                d="M17.5 0C7.84991 0 0 7.84991 0 17.5C0 27.1501 7.84991 35 17.5 35C27.1501 35 35 27.1501 35 17.5C35 7.84991 27.1501 0 17.5 0ZM17.5 32.2889C9.34644 32.2889 2.71106 25.6536 2.71106 17.5C2.71106 9.34644 9.34644 2.71106 17.5 2.71106C25.6549 2.71106 32.2889 9.34644 32.2889 17.5C32.2889 25.6536 25.6536 32.2889 17.5 32.2889Z"
                                                fill="#333333" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1_485">
                                                <rect width="35" height="35" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>

                                    <p>{{'END_DATE'|translate}}:
                                        {{event.endDateTime|
                                        date:'dd.MM.yyyy hh:mm'}}</p>

                                </div>

                                <div class="events-detail-list" *ngIf="event?.endAppealDate">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 35 35"
                                        fill="none">
                                        <g clip-path="url(#clip0_1_485)">
                                            <path
                                                d="M23.7356 20.5906L18.8557 16.9306V9.47519C18.8557 8.72557 18.2497 8.11963 17.5001 8.11963C16.7505 8.11963 16.1445 8.72557 16.1445 9.47519V17.6085C16.1445 18.0354 16.3452 18.4381 16.6868 18.6929L22.1089 22.7595C22.3529 22.9425 22.6376 23.0307 22.9209 23.0307C23.3343 23.0307 23.741 22.8449 24.0067 22.4871C24.4568 21.8892 24.3348 21.0393 23.7356 20.5906Z"
                                                fill="#333333" />
                                            <path
                                                d="M17.5 0C7.84991 0 0 7.84991 0 17.5C0 27.1501 7.84991 35 17.5 35C27.1501 35 35 27.1501 35 17.5C35 7.84991 27.1501 0 17.5 0ZM17.5 32.2889C9.34644 32.2889 2.71106 25.6536 2.71106 17.5C2.71106 9.34644 9.34644 2.71106 17.5 2.71106C25.6549 2.71106 32.2889 9.34644 32.2889 17.5C32.2889 25.6536 25.6536 32.2889 17.5 32.2889Z"
                                                fill="#333333" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1_485">
                                                <rect width="35" height="35" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>

                                    <p>{{'END_APPEAL_DATE'|translate}}: {{event?.endAppealDate| date:'dd.MM.yyyy hh:mm'}}</p>

                                </div>
                                <div class="events-detail-list" *ngIf="event.quota">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 35 35"
                                        fill="none">
                                        <g clip-path="url(#clip0_1_492)">
                                            <path
                                                d="M29.8744 5.12565C26.569 1.82034 22.1744 0 17.5 0C12.8256 0 8.43097 1.82034 5.12559 5.12565C1.82034 8.43097 0 12.8256 0 17.5C0 22.1744 1.82034 26.569 5.12559 29.8743C8.43097 33.1797 12.8256 35 17.5 35C22.1744 35 26.569 33.1797 29.8744 29.8743C33.1797 26.569 35 22.1744 35 17.5C35 12.8256 33.1797 8.43097 29.8744 5.12565ZM17.5 32.9492C13.3407 32.9492 9.56061 31.2965 6.78043 28.6139C8.28598 24.0138 12.6114 20.8496 17.5 20.8496C22.3886 20.8496 26.714 24.0138 28.2196 28.614C25.4394 31.2965 21.6593 32.9492 17.5 32.9492ZM12.373 13.6719C12.373 10.8449 14.673 8.54492 17.5 8.54492C20.327 8.54492 22.627 10.8449 22.627 13.6719C22.627 16.4989 20.327 18.7988 17.5 18.7988C14.673 18.7988 12.373 16.4989 12.373 13.6719ZM29.7596 26.8894C28.2509 23.3597 25.2886 20.6777 21.7084 19.4827C23.5063 18.177 24.6777 16.0588 24.6777 13.6719C24.6777 9.71407 21.4578 6.49414 17.5 6.49414C13.5422 6.49414 10.3223 9.71407 10.3223 13.6719C10.3223 16.0588 11.4937 18.177 13.2917 19.4827C9.71141 20.6777 6.74912 23.3597 5.24043 26.8894C3.24119 24.2853 2.05078 21.0293 2.05078 17.5C2.05078 8.98126 8.98126 2.05078 17.5 2.05078C26.0187 2.05078 32.9492 8.98126 32.9492 17.5C32.9492 21.0293 31.7588 24.2853 29.7596 26.8894Z"
                                                fill="black" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1_492">
                                                <rect width="35" height="35" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <p>
                                        {{'QUOTA'|translate}}:&nbsp; {{event.quota}} {{'FOR_PEOPLE'|translate}}
                                     </p>
                                </div>
                                <div class="events-detail-list">
                                    <i class="fa-solid fa-money-bill"></i>
                                    <p *ngIf="event.price > 0">{{event.price}} €</p>
                                    <p *ngIf="event.price==0">{{'FREE'|translate}}</p>
                                </div>

                                <a *ngIf="event.longitude && event.latitude" href="http://www.google.com/maps/place/{{event.longitude}},{{event.latitude}}"
                                    target="_blank">
                                    <div class="type-info-box">
                                        {{'SHOW_MAP'|translate}}
                                    </div>
                                </a>


                            </div>

                        </div>
                    </div>







                </div>
            </div>

        </div>




    </div>
</div>