import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DepartmentService } from '../../../services/department.service';

@Component({
  selector: 'app-advisor-page',
  templateUrl: './advisor-page.component.html',
  styleUrls: ['./advisor-page.component.scss']
})
export class AdvisorPageComponent implements OnInit {
  list: any;
  photoUrl = environment.baseServerUrl
  lang:any;


  constructor(private departmentService: DepartmentService) { }
  ngOnInit(): void {
    this.departmentService.getAllDepartmentsUsers().subscribe((data: any) => {
      this.list=data.data.filter(data=>data.id!==10);

    })
    this.lang=localStorage.getItem('language');

  }
}
