import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss']
})
export class EventCardComponent {
  @Input() item: any;
  photoUrl: string = environment.serverUrl
  basePhotoUrl: string = environment.baseServerUrl
}
