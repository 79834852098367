import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ContentService } from 'src/app/services/content.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-homepage-news',
  templateUrl: './homepage-news.component.html',
  styleUrls: ['./homepage-news.component.scss']
})
export class HomepageNewsComponent implements OnInit {
  news: any[] = [];
  newsId = 10;

  constructor(private contentService: ContentService) { }

  ngOnInit() {
    const url = `GetAllContentResource`
    this.contentService.getBaseContentData(url, this.newsId).subscribe((data: any) => {
      this.news = data;
    })

  }



  blogSlides: OwlOptions = {
    loop: false,
    nav: true,
    dots: true,
    autoplayHoverPause: true,
    autoplay: false,
    margin: 10,
    navText: [
      "<i class='bx bx-left-arrow-alt'></i>",
      "<i class='bx bx-right-arrow-alt'></i>"
    ],
    responsive: {
      0: {
        items: 1
      },
      500: {
        items: 2
      },
      768: {
        items: 3
      },
      1200: {
        items: 4
      }
    }
  }
}
