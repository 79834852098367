import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutPageTwoComponent } from './components/pages/about-page-two/about-page-two.component';
import { ErrorPageComponent } from './components/pages/error-page/error-page.component';
import { ContactPageComponent } from './components/pages/contact-page/contact-page.component';
import { HomePageEightComponent } from './components/pages/home-page-eight/home-page-eight.component';
import { NewsComponent } from './components/pages/news/news.component';
import { NewsResolver } from './components/pages/news/news.resolver';
import { WebPagesComponent } from './components/pages/web-pages/web-pages.component';
import { WebPagesResolver } from './components/pages/web-pages/web-pages.resolver';
import { LayoutComponent } from './core/layout/layout.component';
import { EventsPageComponent } from './components/pages/events-page/events-page.component';
import { EducationPageComponent } from './components/pages/education-page/education-page.component';
import { PublishPageComponent } from './components/pages/publish-page/publish-page.component';
import { PublishInfoPageComponent } from './components/pages/publish-page/publish-info-page/publish-info-page.component';
import { SalmonPageComponent } from './components/pages/sermon-page/salmon-page.component';
import { NewsInfoPageComponent } from './components/pages/news/news-info-page/news-info-page.component';
import { AssociationsComponent } from './components/pages/associations/associations.component';
import { AdvisorPageComponent } from './components/pages/advisor-page/advisor-page.component';
import { AboutPageOneComponent } from './components/pages/about-page-one/about-page-one.component';
import { PublishTypePageComponent } from './components/pages/publish-page/publish-type-page/publish-type-page.component';
import { PublishResolver } from './components/pages/publish-page/publish.resolver';
import { EducationResolver } from './components/pages/about-page-two/education.resolver';
import { TabsDetailComponent } from './components/pages/tabs-detail/tabs-detail.component';
import { TabsDetailResolver } from './components/pages/tabs-detail/tabs-detail.resolver';
import { MemoriesComponent } from './components/pages/memories/memories.component';
import { EventsDetailComponent } from './components/pages/events-page/events-detail/events-detail.component';
import { EventsResolver } from './components/pages/events-page/events.resolver';


const routes: Routes = [

    {
        path: '',
        component: LayoutComponent,
        children: [
            { path: '', component: HomePageEightComponent },
            { path: 'odeme', redirectTo: 'sayfa/genel-bagis/8' },
            { path: 'yardimlasma-fonu-aidat-miktari', redirectTo:'sayfa/cenaze-fonu/7' },
            { path: 'etkinlikler', component: EventsPageComponent },
            { path: 'egitim', component: EducationPageComponent },
            { path: 'yayinlar', component: PublishPageComponent },
            { path: 'yayinlar/:name/:id/:base', component: PublishTypePageComponent },
            {
                path: 'yayin-detay/:id/:base', component: PublishInfoPageComponent,
                resolve: { publish: PublishResolver }
            },
            { path: 'hutbeler', component: SalmonPageComponent },
            { path: 'haberler', component: NewsComponent },
            { path: 'anilar', component: MemoriesComponent },
            {
                path: 'haber-detay/:id/:base', component: NewsInfoPageComponent,
                resolve: { news: NewsResolver }
            },
            {
                path: 'ani-detay/:id', component: TabsDetailComponent,
                resolve: { tabdetail: TabsDetailResolver }
            },
            { path: 'dernekler', component: AssociationsComponent },
            // { path: 'online-bagis', component: OnlineDonateComponent },
            { path: 'birimler', component: AdvisorPageComponent },
            { path: 'birim-detay/:code', component: AboutPageOneComponent },
            { path: 'dernekler', component: AssociationsComponent },
            { path: 'iletisim', component: ContactPageComponent },
            {
                path: 'etkinlik-detay/:code/:base', component: EventsDetailComponent,
                resolve: { data: EventsResolver }
            },
            {
                path: 'egitim-detay/:id/:base', component: AboutPageTwoComponent,
                resolve: { data: EducationResolver }
            },
            {
                path: 'sekme-detay/:id', component: TabsDetailComponent,
                resolve: { tabdetail: TabsDetailResolver }
            },
            {
                path: 'sayfa/:slug/:id/:base', component: WebPagesComponent,
                resolve: { data: WebPagesResolver }
            },
            {
                path: 'sayfa/:slug/:id', component: WebPagesComponent,
                resolve: { data: WebPagesResolver }
            }
        ]
    },

    { path: '**', component: HomePageEightComponent }

];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }