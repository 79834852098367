import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-news-card',
  templateUrl: './news-card.component.html',
  styleUrls: ['./news-card.component.scss']
})
export class NewsCardComponent {
  @Input()
  item: any;
  photoUrl: string = environment.serverUrl
  basePhotoUrl: string = environment.baseServerUrl
}
