import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { environment } from 'src/environments/environment';
import { DepartmentService } from '../../../services/department.service';

@Component({
  selector: 'app-about-page-one',
  templateUrl: './about-page-one.component.html',
  styleUrls: ['./about-page-one.component.scss']
})
export class AboutPageOneComponent implements OnInit {
  id: string;
  user: any;
  photoUrl=environment.baseServerUrl
  constructor(private activatedRoute: ActivatedRoute, private departmentService: DepartmentService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: { code: string }) => {
      this.id = params.code;
    })
    this.getUser();
  }
  getUser() {
    this.departmentService.getDepartmentUserById(this.id).subscribe((user: any) => {
      this.user = user.data;

    })
  }
}
