<div class="publish-image">
    <a [routerLink]="item.isShare === true ? ['/yayin-detay',item.id,'true'] : ['/yayin-detay',item.id,'false']"
        class="d-block">
        <img [src]="item?.thumbnail  ? ( item.isShare === true ? basePhotoUrl + item?.thumbnail : photoUrl+item?.thumbnail) : ['assets/img/fransaPhotos/LyonLogo.svg']"
            alt="image">
    </a>
</div>
<div class="publish-info">
    <a [routerLink]="item.isShare === true ? ['/yayin-detay',item.id,'true'] : ['/yayin-detay',item.id,'false']"
        class="d-block ">
        <div class="line-clamp-four">
            <p>{{item?.publicationName}}</p>
            {{item?.writerName}}
        </div>
    </a>
</div>