<app-banner [data]="{content: 'EVENTS' | translate, title: 'EVENTS' | translate}"></app-banner>

<div class="container events pt-100 pb-100">
    <div class="title-search mt-30 mb-30">
        <!-- <h4>Bu ay ki etkinlikler</h4> -->
        <div class="search-bar">
            <svg class="svg-icon search-icon" aria-labelledby="title desc" width="18" height="18" role="img"
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.9 19.7">
                <g class="search-path" fill="none" stroke="#848F91">
                    <path stroke-linecap="square" d="M18.5 18.3l-5.4-5.4" />
                    <circle cx="8" cy="8" r="7" />
                </g>
            </svg>
            <input type="text" (input)="onChange($event)" placeholder="Ara...">
        </div>
        <mat-form-field>
            <mat-label>{{'SEARCH_BY_DATE'|translate}}</mat-label>
            <mat-date-range-input [rangePicker]="picker">
                <input matStartDate #dateRangeStart (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)"
                    placeholder="Başlangıç">
                <input matEndDate (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)" #dateRangeEnd
                    placeholder="Bitiş">
            </mat-date-range-input>
            <mat-hint>GG/AA/YYYY – GG/AA/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>

        </mat-form-field>

    </div>
    <div class="blog-slides ">
        <div class="row ">

            <div class="col-lg-2 col-md-3 col-sm-6 " *ngFor="let item of events">
               
                    <app-event-card [item]="item"></app-event-card>

            </div>

        </div>

    </div>
    <ng-container *ngIf="totalCount === 0">
        <div class="row">
            <div class="no-data col-sm-12 ml-4 mt-3">
                <label class="font-weight-bold"> <img src="assets/img/fransaPhotos/no-data.png" alt="">
                    {{'NO_DATA_FOUND' | translate}}</label>
            </div>
        </div>
    </ng-container>
    <mat-paginator [length]="totalCount" [pageSize]="pageSize" [hidePageSize]="'true'" (page)="onPageChange($event)"
        [showFirstLastButtons]="true"></mat-paginator>
</div>