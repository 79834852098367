<div class="gym-home-slides">
    <owl-carousel-o [options]="gymHomeSlides">
        <ng-template carouselSlide *ngFor="let slide of slider ; let i = index">
            <a *ngIf="slide?.isShare=== true"
                [href]="slide?.link ? slide?.link : (slide?.frontPageId ? '/sayfa/slider-detay/'+ slide.frontPageId +'/true'  : '' )"
                [target]="slide?.link ? '_blank' : ''"><img [src]="basePhotoUrl+slide?.contentImages[0]?.imagePath"
                    alt=""></a>
            <a *ngIf="slide?.isShare!=true"
                [href]="slide?.link ? slide?.link : (slide?.frontPageId ? '/sayfa/slider-detay/'+ slide.frontPageId+'/false' : '' )"
                [target]="slide?.link ? '_blank' : ''"><img [src]="photoUrl+slide?.contentImages[0]?.imagePath"
                    alt=""></a>
        </ng-template>

    </owl-carousel-o>
</div>