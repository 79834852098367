<app-banner [data]="detail.contentTypeId== 28 ? {content: 'MEMORIES' | translate, title: detail.title , link:'/anilar'} : {content: 'ANNOUNCEMENT_LITTLE' | translate, title: detail.title}"></app-banner>


<div class="blog-details-area pt-70 pb-100">
    <div class="container">
        <div class="row">
            <div>
                <div class="blog-details-desc">
                    <div class="news-title">
                        <!-- <h4>{{detail.title}}</h4> -->
                    </div>
                    &nbsp;
                    <div class="mission-area ptb-70" *ngIf="detail?.contentImages?.length > 0">
                        <div class="container">
                            <div class="mission-content">
                                <div class="mission-slides">
                                    <owl-carousel-o [options]="missionSlides">
                                        <ng-template carouselSlide *ngFor="let item of detail?.contentImages">
                                            <img [src]="photoUrl+item.imagePath">
                                        </ng-template>
                                    </owl-carousel-o>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="container">
                        <div class="article-content">
                            <p [innerHTML]="detail.description"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>