<app-banner
    [data]="{content: 'ASSOCIATION' | translate, title: 'ASSOCIATION' | translate}"></app-banner>

<div class="container events education pt-70 pb-100">

    <!-- <div class="title-search">
  
        <div class="search-bar">
            <svg class="svg-icon search-icon" aria-labelledby="title desc" width="18" height="18" role="img"
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.9 19.7">
                <g class="search-path" fill="none" stroke="#848F91">
                    <path stroke-linecap="square" d="M18.5 18.3l-5.4-5.4" />
                    <circle cx="8" cy="8" r="7" />
                </g>
            </svg>
            <input type="text" placeholder="Ara...">
        </div>
    </div> -->
    <div class="blog-slides container">
        <div class="row">

            <div class="col-md-3" *ngFor="let item of list">
                <div class="single-courses-box mb-30">

                    <div class="courses-image ">
                        <a>
                            <p>{{item.city}}</p>
                        </a>

                    </div>

                    <div class="courses-content">
                        <p><a class="d-inline-block"> {{item.title}}</a></p>
                        <div class="events-info-box row">
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21"
                                fill="none">
                                <path
                                    d="M10.5 0C6.30635 0 2.89459 3.41176 2.89459 7.60536C2.89459 12.8098 9.70068 20.4501 9.99046 20.7728C10.2626 21.076 10.7378 21.0754 11.0095 20.7728C11.2993 20.4501 18.1054 12.8098 18.1054 7.60536C18.1053 3.41176 14.6936 0 10.5 0ZM10.5 11.4318C8.39007 11.4318 6.67357 9.71529 6.67357 7.60536C6.67357 5.49544 8.39011 3.77893 10.5 3.77893C12.6099 3.77893 14.3264 5.49548 14.3264 7.6054C14.3264 9.71533 12.6099 11.4318 10.5 11.4318Z"
                                    fill="black" />
                            </svg>
                            &nbsp;&nbsp;{{item.address}}
                        </div>

                    </div>
                    <div class="type-info-area">

                        <a href="http://www.google.com/maps/place/{{item.longitude}},{{item.latitude}}" target="_blank">

                            <div class="type-info-box">

                                {{'SHOW_MAP'|translate}}
                            </div>
                        </a>

                    </div>

                </div>
            </div>

        </div>

    </div>
</div>