import { Component, OnInit } from '@angular/core';
import { DepartmentService } from '../../../services/department.service';

@Component({
  selector: 'app-associations',
  templateUrl: './associations.component.html',
  styleUrls: ['./associations.component.scss']
})
export class AssociationsComponent implements OnInit {
  list: any
  constructor(private departmentService: DepartmentService) { }
  ngOnInit(): void {
    this.departmentService.getAllDepartmentsById(10).subscribe((data) => {
      this.list = data.data.filter(department => department.isActive === true && department.isDeleted == false);
    })
  }

}
