<div class="single-courses-box mb-30">
    <div class="courses-image">

        <a [routerLink]="item.isShare === true ? ['/haber-detay', item.id,'true'] : ['/haber-detay', item.id,'false']" class="d-block"><img
            [src]="item?.thumbnail  ? ( item.isShare === true ? basePhotoUrl+item?.thumbnail : photoUrl+item?.thumbnail) : ['assets/img/fransaPhotos/LyonLogo.svg']"
                alt="image"></a>


    </div>
    <div class="courses-content">
        <a [routerLink]="item.isShare === true ? ['/haber-detay', item.id,'true'] : ['/haber-detay', item.id,'false']">
            <p class="line-clamp-six">
                {{item.title}}</p>
            <!-- <p class="new-contents line-clamp-three" [innerHTML]="item?.description"></p> -->
        </a>
    </div>
    <div class="courses-box-footer">
        <a [routerLink]="item.isShare === true ? ['/haber-detay', item.id,'true'] : ['/haber-detay', item.id,'false']">
            <button class="view-all-contents-button">
                {{'SEE_MORE' | translate}}
            </button>
        </a>
        <div class="courses-box-date">
            <p>
                {{item?.creationDate | date:'dd.MM.yyyy'}}
            </p>

        </div>
    </div>
</div>