import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tabs-detail',
  templateUrl: './tabs-detail.component.html',
  styleUrls: ['./tabs-detail.component.scss']
})
export class TabsDetailComponent implements OnInit {
  detail: any
  photoUrl: string = environment.serverUrl

  constructor(private activeRoute: ActivatedRoute,private sanitizer: DomSanitizer) { }


  ngOnInit(): void {
    this.activeRoute.data.subscribe(
      (data: { tabdetail: any }) => {
        if (data.tabdetail) {
          this.detail = data.tabdetail.data;
          this.detail.description = this.sanitizer.bypassSecurityTrustHtml(this.detail.description);

        }
      });
  }



  missionSlides: OwlOptions = {
    loop: true,
    nav: true,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    items: 1,
    navText: [
      "<i class='bx bx-left-arrow-alt'></i>",
      "<i class='bx bx-right-arrow-alt'></i>"
    ]
  }

}
