import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-about-page-two',
  templateUrl: './about-page-two.component.html',
  styleUrls: ['./about-page-two.component.scss']
})
export class AboutPageTwoComponent implements OnInit {
  education: any;
  photoUrl = environment.serverUrl
  basePhotoUrl = environment.baseServerUrl

  constructor(private activeRoute: ActivatedRoute, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {

    this.activeRoute.data.subscribe(
      (data: { data: any }) => {
        if (data.data) {
          this.education = data?.data?.data;
          this.education.description = this.sanitizer.bypassSecurityTrustHtml(this.education.description);
        }
      });
  }



  missionSlides: OwlOptions = {
    loop: true,
    nav: true,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    items: 1,
    navText: [
      "<i class='bx bx-left-arrow-alt'></i>",
      "<i class='bx bx-right-arrow-alt'></i>"
    ]
  }



}
